import { waitLoad } from "@blax-modules/base-front-end/resources/js/utilities/load"

export class Menu {
    constructor() {
        waitLoad().then(() => {
            this._bindButton();
            this._bindNav();
        })
    }

    _bindNav() {
        let icoBtn = document.querySelectorAll('.ico-menu');
        for (let i in icoBtn) {
            if (icoBtn.hasOwnProperty(i)) {
                let btn = icoBtn[i];
                btn.addEventListener('click', () => {
                    let mobileNav = document.querySelector('.site-mobile-nav');
                    if (mobileNav.classList.contains('in-left')) {
                        mobileNav.classList.remove('in-left')
                        mobileNav.classList.add('in-right')
                    } else if (mobileNav.classList.contains('in-right')) {
                        mobileNav.classList.add('in-left')
                        mobileNav.classList.remove('in-right')
                    } else {
                        mobileNav.classList.add('in-left')
                    }
                })
            }
        }
    }

    _bindButton() {
        let openBtn = document.querySelector('#open-menu-button')
        let menu = document.querySelector('.hamburger-ctn')
        let bodyEl = document.querySelector('body');

        if (menu) {
            menu.addEventListener('click', () => {
                openBtn.checked = !openBtn.checked;
                menu.classList.toggle('opened')
                bodyEl.classList.toggle('menu-open')
            })

        }
        //everything after the header should close the menu
        let afterMenuEl = document.querySelectorAll('.site-header ~ *');
        for (let i in afterMenuEl) {
            if (afterMenuEl.hasOwnProperty(i)) {
                let el = afterMenuEl[i];
                el.addEventListener('click', () => {
                    document.querySelector('body').classList.remove('mobile-menu-open')
                })
            }
        }
    }
}
